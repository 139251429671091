.manhunter-dashboard-rt{
	.manhunter-dashboard-filters{
		display: flex;
		justify-content: flex-end;
		margin: 20px 0px 20px 0px;
		:global(.select-rt){
			float: left;
			margin-left: 10px;
		}

	}
	.panelChartEmployeesServices{
		:global(.panel-rt-body){
			height: 300px;
		}
	}
}