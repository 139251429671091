html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    body{
        background-color: #E6E7EC;
        .chronos-app{
            padding-right: 15px;
            padding-left: 15px;
        }
        margin-right: auto;
        margin-left: auto;
        &:after, &:before{
            display: table;
            content: " ";
        }
    }

  }
  
*,*::before,*::after {
    box-sizing: inherit;
}