@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.select-scale-type-rt{
	@include componentDefaultFonts();
	.select-scale-type-title{
		font-weight: bold;
		margin-bottom: 20px;
	}
	.select-scale-type-container{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		cursor: pointer;
		.select-scale-type-box{
			@include borderRadius(10px);
			position: relative;
			min-width: 300px;
			max-width: 300px;
			flex: 1;
			margin-right: 15px;
			margin-bottom: 15px;
			border: 1px solid $primary-color-light;
			color: $primary-color-light;
			&:last-of-type{
				margin-right: 0px;
			}

			&.selected{
				border-color: $green-color-light;
				color: $green-color-light;
				&:after{
					@include transition(all, 5s);
					@include borderRadius(100%);
					content: '\2713';
					font-size: 18px;
					position: absolute;
					color: $green-color-light;
					width: 20px;
					height: 20px;
					line-height: 20px;
					padding-left: 3px;
					border:1px solid $green-color-light;
					right: 0;
					bottom: 0;
					margin: 0px 5px 5px 0px;
				}
				.select-scale-type-box-title{
					background-color: $green-color;
				}
			}
			.select-scale-type-box-title{
				@include borderRadiusTopLeft(8px);
				@include borderRadiusTopRight(8px);
				padding: 5px;
				text-align: center;
				color: #ffffff;
				font-weight: bold;
				background-color: $primary-color;
			}
			.select-scale-type-box-body{
				height: 90px;
				font-size: 12px;
				ul{
					list-style-type: none;
					padding: 10px;
					margin: 0px;
				}
			}
		}	
	}
}