@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.scale-new-step1-rt{
	@include componentDefaultFonts();
	.form-scale-new-actions{
		margin-top: 20px;
		:global(.btn-rt){
			@include clearfix();
			float: right;
			margin-right: 10px;
			&:first-child{
				margin-right: 0px;
			}

			
		}
	}
}