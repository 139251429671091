@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.table-list-scale-rt{
	@include componentDefaultFonts();
	.table-list-scale-actions{
		float: right;
		margin-right: 15px;
	}
	.table-scale{
		text-align: center;
		font-size: 12px;
		border: none;
		.table-header-action{
			background-color: transparent;
		}
		.table-header{
			//Tamanho fixo para primeira e última coluna da tabela
			th{
				&:first-of-type{
					width: 220px;
				}
				&:last-of-type{
					width: 30px;
					padding: 0px 2px;
					// border-left: 1px solid $primary-color-light;
				}
			}
			.table-header-date{
				font-size: 12px;
				text-align: center;
			}
			
			.table-header-weekday{
				font-size: 10px;
				text-align: center;
			}
			
		}
		.box-scale-day{
			max-width: 100%;
			min-width: 100px;
			margin: 0 auto;
			margin-bottom: 5px;
		}

		.line{
			:global(.avatar-name){
				text-align: center;
				div{
					float: none;
				}
			}
			td{
				&:last-of-type{
					padding: 0px 2px;
					background-color: lighten($primary-color-light,10%) !important;
					border-left: 1px solid $primary-color-light;
					font-weight: 14px;
					color: #ffffff;
				}
			}
			.week-work-total{
				font-weight: bold;

			}
		}
		
		/*.day-action{
			display: none;
		}
		
		.last-line-action{
			td{
				text-align: left;
			}
		}*/

		/*td{
			&:hover{
				.day-action{
					display: block;
				}
			}
		}*/
	}

	.modal-new-scale-employee{
		:global(.modal-new-rt-container .modal-new-rt-body){
			overflow-y: unset !important;
		}
		:global(.modal-new-rt-body){
			
			.form-scale-employee-new-actions{
				text-align: center;
				margin-top: 20px;
				:global(.btn-rt){
					@include clearfix();
					margin-right: 10px;
					&:first-child{
						margin-right: 10px;
					}				
				}
			}
			
		}
	}

    .modal-copy-past{
		:global(.modal-new-rt-container .modal-new-rt-body){
			overflow-x: unset !important;
            overflow-y: auto !important;
		}
	}

	:global(.modal-new-rt-container .modal-new-rt-body){
		overflow-y: unset !important;
	}	
}
