@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.form-scale-area{
	input{
		margin-bottom: 15px;
	}
	.form-scale-area-actions{
		:global(.btn-rt){
			@include clearfix();
			float: right;
			margin-right: 10px;
			&:first-child{
				margin-right: 0px;
			}

			
		}
	}
}
