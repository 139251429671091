@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';
.scale-day-rt{
	@include componentDefaultFonts();
	.box-scale-day-rt{
		@include borderRadius(10px);
		font-size: 12px;
		color: #ffffff;
		// max-width: 100px;
		// min-width: 100px;
		// margin: 0 auto;

		&.box-scale-day-absence{
			background-color: $yellow-color-light;
			.box-scale-header{
				background-color: $yellow-color;
			}
		}

        &.box-scale-day-off{
			background-color: $red-color-light;
			.box-scale-header{
				background-color: $red-color;
                padding: 5px 0px;
			}
		}
		&.box-scale-day-work{
			background-color: $green-color-light;
			
			.box-scale-header{
				background-color: rgba(0, 0, 0, 0.2);
				padding: 5px 0px;
			}
		}
		
		.box-scale-header{
			@include borderRadiusTopRight(10px);
			@include borderRadiusTopLeft(10px);
			font-weight: 500;
			position: relative;
            word-wrap: break-word;
            overflow-wrap: break-word;
            white-space: normal;
            white-space: -moz-pre-wrap;
            white-space: pre-wrap;
            width: auto;
			.box-scale-actions{
				position: absolute;
				top: 2px;
				right: 1px;
				.action-dropdown{
					:global(.button-dropdown-button){
						background: none;
						padding: 0;
						border: none;
						color: #ffffff;
						&:hover{
							background-color: rgba(0, 0, 0, 0.3);
						}
					

					}
				}
			}
		}
		.box-scale-body{
			white-space: initial;
            padding: 5px 1px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            white-space: -moz-pre-wrap;
            white-space: pre-wrap;
            width: auto;

		}
		.box-scale-footer{
			@include borderRadiusBottomRight(10px);
			@include borderRadiusBottomLeft(10px);
			min-height: 15px;
			font-size: 10px;
			background-color: rgba(0, 0, 0, 0.1);
			line-height: 15px;
		}

		@media (max-width: 600px) {
			font-size: 12px;
			.box-scale-header{
				.box-scale-header-scale-abbreviation{
					display: block;
				}
				.box-scale-header-scale-name{
					display: none;
				}
			}
		}

		@media (min-width: 601px) {
			.box-scale-header{
				.box-scale-header-scale-abbreviation{
					display: none;
				}
				.box-scale-header-scale-name{
					display: block;
				}
			}
		}

	}

}