@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.scale-hour-new-rt{
	label{
		margin-top: 10px;
	}
	
	:global(.avatar-name){
		text-align: center;
		div{
			float: none;
		}
	}

	.scale-validation-box{
		@include borderRadius(10px);
		border: 1px solid $red-color-light;
		margin-bottom: 10px;
		font-size: 12px;
		.scale-validation-box-title{
			@include borderRadiusTopLeft(9px);
			@include borderRadiusTopRight(9px);
			padding: 5px 5px;
			background-color: $red-color;
			color: #ffffff;
		}
	}
	
		
	.scale-hour-new-actions{
		text-align: center;
		margin-top: 20px;
		:global(.btn-rt){
			@include clearfix();
			margin-right: 10px;
			&:first-child{
				margin-right: 10px;
			}				
		}
	}
}