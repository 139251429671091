@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.scale-new-step3-rt{
	@include componentDefaultFonts();
	.form-scale-add-restrictions-actions{
		margin-top: 20px;
		:global(.btn-rt){
			@include clearfix();
			float: right;
			margin-right: 10px;
			&:first-child{
				margin-right: 0px;
			}

			
		}
	}

}