@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.scale-area-list-rt{
	.scale-area-filters{
		:global(.btn-rt){
			margin-top: 24px;
		}
	}
	.actions{
		@include clearfix();
		:global(.btn-rt){
			float: right;
			margin: 0px;
		}

	}
	.modal-delete{
        text-align: center;
		.modal-delete-action{
			margin-right: 10px;
		}
    }
	
	.list{
		.employee-name{
			>div{
				float: left;
				line-height: 24px;
				&:first-of-type{
					margin-right: 10px;
				}
			}
		}
		.scale-area-color{
			@include borderRadius(100%);
			width: 24px;
			height: 24px;

		}
		.scale-area-actions{
			.button-action{
				flex-grow: 3;
				margin-right: 5px;
			}
		}		
	}
}