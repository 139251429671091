@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.scale-employee-new-rt{
	.scale-employee-new-actions{
		text-align: center;
		margin-top: 20px;
		:global(.btn-rt){
			@include clearfix();
			margin-right: 10px;
			&:first-child{
				margin-right: 10px;
			}				
		}
	}
}